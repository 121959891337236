import React, { useEffect, useContext, useState } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { GatsbyImage } from "gatsby-plugin-image"
import { getPublicPathGatsbyImage } from '../../custom/functions'

import { clientsSVGS } from '../../custom/svgs'

import { Link } from 'gatsby'
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import '../../sass/test.scss'

const ClientsLogos = ({ data, publicsUrl, mobile }) => {
  const { language } = useI18next()
  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const [expand, setExpand] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setExpand(!expand);
    }, 50000);
  }, [expand]);


  return (
    <div className="container-margin pt-1 m-auto text-center container-clients">
      <Swiper
        grabCursor={true}
        a11y={false}
        speed={5000}
        loop={true}
        slidesPerView={'auto'}
        modules={[Autoplay, Navigation, Pagination, Scrollbar]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        showPagination={false}
        navigation={false}
        className="mySwiper"
      >
        {data.clients.logos.map((logo, j) => (
          <SwiperSlide key={'logo'+j}>
            <Link to={translateUrl(logo.link, language)} >
              {!mobile ?
                //  clientsSVGS[logo.img] 
                //<div className="container-svg-clients" dangerouslySetInnerHTML={{ __html: parser.parseFromString(clientsSVGS("Exporlux"), "text/xml").firstChild.innerHTML }} />
                <div className="container-svg-clients">
                  {clientsSVGS(logo.img)}
                </div>
                :
                <GatsbyImage
                  image={getPublicPathGatsbyImage(publicsUrl, logo.imghover)}
                  alt={logo.alt}
                  key={'key' + j}
                />
              }
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div >
  );
}
export default ClientsLogos;