import React, { useContext, useEffect, useState } from 'react'
import { InView } from "react-intersection-observer";

import { useAnimation, motion } from "framer-motion";
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'

import GeneralButton from "../generalButton";


const Team = ({ data, mobile }) => {

  const { language } = useI18next()

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const [expand, setExpand] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setExpand(!expand);
    }, 50000);
  }, [expand]);


  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `5vh`,
    },
    visible: {
      opacity: 1,
      y: `0vh`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  const ctrls = useAnimation();

  function updateTextAnimation(inView) {

    if (inView) {
      ctrls.start("visible");
    }

    if (!inView) {
      ctrls.start("hidden");
    }
  }

  return (
    <div className="container-margin pt-1 m-auto text-center">
      <InView as="div" threshold={0.5}
        onChange={inView => {
          updateTextAnimation(inView)
        }}
      >
        <motion.div
          initial="hidden"
          animate={ctrls}
          variants={characterAnimation}
          style={{ width: mobile ? '100%' : '70%', margin: 'auto' }}
        >
          {/**<p className=" uppercase title-white-border">{data.title}</p>*/}
          <div className="container-team-video mt-2">
            <video  className="team-video " controls poster={data.videoPoster}>
              <source src={data.video} type="video/mp4"  />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container-team-info mt-2">
            {/**<p className="ILightBeta white fs-6 text-justify vertical-center">{data.info}</p>*/}
            <div className={mobile ? 'p-relative text-right vertical-center mt-2 ' : 'p-relative text-right vertical-center '}>
              <GeneralButton text={data.btntext} url={translateUrl(data.btnlink, language)} />
            </div>
          </div>
        </motion.div>
      </InView>
    </div>
  );
}
export default Team;